import { useState, useCallback } from "react";
import { Row } from "react-bootstrap";

import { ResourceExplorerFile } from "./ResourceExplorerFile";

import "./FileDropZone.css";

interface FileDropZoneProps {
    files: string[];
    project: string;
    selectedPack: string;
    selectedPath: string;
    lastSelected: string | null;
    fileFilter: string[];
    onSelect: (file: string, pack: string) => void;
    onDelete: (file: string) => void;
    onDropFiles: (files: File[]) => void;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ files, project, selectedPack, selectedPath, lastSelected, fileFilter, onSelect, onDelete, onDropFiles }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files.length > 0) {
            onDropFiles(Array.from(e.dataTransfer.files));
        }
    }, [onDropFiles]);

    return (
        <div 
            className="col-6 p-3 content-column position-relative"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <Row>
                {files.map((file, index) => (
                    <ResourceExplorerFile
                        project={project}
                        key={index}
                        pack={selectedPack}
                        path={file}
                        highlight={lastSelected === `${selectedPack}:${file}` || fileFilter.includes(file.split(".")?.pop() ?? "")}
                        onSelect={() => onSelect(file, selectedPack)}
                        onDeleted={() => onDelete(file)}
                    />
                ))}
            </Row>

            {isDragging && (
                <div className="drop-overlay">
                    <div className="drop-text">Drop files here to uploaded them to {selectedPack}:{selectedPath}</div>
                </div>
            )}
        </div>
    );
};

export default FileDropZone;
