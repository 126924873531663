import React, { useEffect, useRef, useState, useCallback } from "react";
import { Terrain, TerrainChunk } from "./Terrain";
import {
    Engine,
    Scene,
    SceneLoader,
    ArcRotateCamera,
    HemisphericLight,
    Vector3,
    AnimationGroup,
    Color4,
    Quaternion,
    TransformNode,
    BoundingInfo,
    ActionManager,
    ExecuteCodeAction,
    AbstractMesh,
    UniversalCamera,
    Mesh,
    DirectionalLight,
    Color3,
    Matrix,
    FreeCameraMouseInput,
    InstancedMesh,
    AssetContainer,
    MeshBuilder,
    Texture,
    ShaderMaterial,
    VertexBuffer,
    Scalar
} from "@babylonjs/core";

interface TerrainLoaderProps {
    engine: Engine;
    scene: Scene;
    terrain: Terrain;
}

async function loadTexture(scene: Scene, url: string): Promise<number[][]> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            if (!context) {
                reject("Failed to create 2D context.");
                return;
            }

            canvas.width = image.width;
            canvas.height = image.height;

            context.drawImage(image, 0, 0, image.width, image.height);

            const data = context.getImageData(0, 0, image.width, image.height).data;
            const result: number[][] = [];
            
            // x, y -> r
            for (let x = 0; x < image.width; x++) {
                result.push([]);
                for (let y = 0; y < image.height; y++) {
                    const i = (y * image.width + x) * 4;
                    result[x].push(data[i]);
                }
            }

            console.log("Loaded texture:", result);

            resolve(result);
        };
    });
}

function emptyTexture(width: number, height: number): number[][] {
    const result: number[][] = [];

    for (let x = 0; x < width; x++) {
        result.push([]);
        for (let y = 0; y < height; y++) {
            result[x].push(0);
        }
    }

    return result;
}

export const TerrainLoader: React.FC<TerrainLoaderProps> = ({ engine, scene, terrain }) => {
    const [terrainNode, setTerrainNode] = useState<TransformNode>();

    const createTerrainChunk = useCallback(async (chunk: TerrainChunk, scene: Scene) => {
        const { flat, path, scale, min, max, translation, width, height } = chunk.heightmap;

        const terrainChunk = new Mesh("terrainChunk", scene);

        // Create vertex data for the terrain
        const subdivisions = width; // Ensure enough vertices
        const vertexData = {
            positions: [] as number[],
            indices: [] as number[],
            normals: [] as number[],
            uvs: [] as number[],
        };

        const imageData = flat ? emptyTexture(width, height) : await loadTexture(scene, path);

        for (let z = 0; z <= subdivisions; z++) {
            for (let x = 0; x <= subdivisions; x++) {
                const xPos = (x / subdivisions) * width - width / 2;
                const zPos = (z / subdivisions) * height - height / 2;

                // Calculate the corresponding pixel in the image data
                const pixelX = Math.floor((x / subdivisions) * (imageData.length - 1));
                const pixelZ = Math.floor((z / subdivisions) * (imageData[0].length - 1));
                const heightValue = imageData[pixelX][pixelZ] / 255.0; // Normalize height (assuming red channel)

                vertexData.positions.push(xPos, heightValue * (max - min) + min, zPos); // Set Y based on height map
                vertexData.uvs.push(x / subdivisions, z / subdivisions);

                if (x < subdivisions && z < subdivisions) {
                    const topLeft = z * (subdivisions + 1) + x;
                    const topRight = topLeft + 1;
                    const bottomLeft = (z + 1) * (subdivisions + 1) + x;
                    const bottomRight = bottomLeft + 1;

                    vertexData.indices.push(topLeft, topRight, bottomLeft);
                    vertexData.indices.push(topRight, bottomRight, bottomLeft);
                }
            }
        }

        // Apply vertex data to the mesh
        terrainChunk.setVerticesData(VertexBuffer.PositionKind, vertexData.positions);
        terrainChunk.setVerticesData(VertexBuffer.NormalKind, vertexData.normals);
        terrainChunk.setVerticesData(VertexBuffer.UVKind, vertexData.uvs);
        terrainChunk.setIndices(vertexData.indices);

        // Position the chunk based on its translation
        terrainChunk.setPositionWithLocalVector(new Vector3(translation[1], 0, translation[0]));
        terrainChunk.scaling.set(scale, 1, scale);
        terrainChunk.rotation.y = Math.PI;
        terrainChunk.alwaysSelectAsActiveMesh = true;

        // Attach a shader material
        const shaderMaterial = new ShaderMaterial("terrainShader", scene, {
            vertex: "/shaders/terrain",
            fragment: "/shaders/terrain",
        }, {
            attributes: ["position", "normal", "uv"],
            uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", "cameraPosition", "time", "heightMap", "hasHeightMap", "scaleMin", "scaleMax"],
        });

        /*shaderMaterial.setInt("hasHeightMap", heightMapTexture ? 1 : 0);

        if (heightMapTexture) {
            shaderMaterial.setTexture("heightMap", heightMapTexture);
        }*/
        shaderMaterial.setFloat("scaleMin", min);
        shaderMaterial.setFloat("scaleMax", max);

        terrainChunk.material = shaderMaterial;

        console.log("Created terrain chunk:", chunk.heightmap);

        return terrainChunk;
    }, [scene]);

    const createTerrain = useCallback(async (chunk: Terrain) => {
        if (!scene) return null;

        const centerX = 204.8 / 2.0;
        const centerY = 204.8 / 2.0;

        // Create a root node for the terrain
        const terrainRoot = new TransformNode("terrainRoot", scene);

        for (let terrainChunk of chunk.chunks) {
            const chunk = await createTerrainChunk(terrainChunk, scene);

            // Attach the chunk to the root node
            chunk.parent = terrainRoot;
        }

        terrainRoot.position.set(centerX, 0, centerY);
        terrainRoot.scaling.set(-1, 1, 1);
        //terrainRoot.rotation.y = Math.PI / 2;

        return terrainRoot;
    }, [scene, createTerrainChunk]);

    useEffect(() => {
        if (terrain && scene) {
            createTerrain(terrain).then((node) => {
                if (!node) {
                    console.error("Failed to create terrain.");
                    return;
                }
                setTerrainNode(node);
            }).catch((err) => {
                console.error("Failed to create terrain:", err);
            });
        }
    }, [terrain, scene, createTerrain]);

    return null;
};
