import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { LogMessage, Severity } from './Logger';
import { World } from './World';
import { Template } from './graphical/TypeTemplate';
import { Terrain } from './Graphics/Terrain';

class NejlikaApi {
    private baseUrl: string;
    private client: AxiosInstance;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
        // Include cookies in requests
        this.client = axios.create({
            baseURL: baseUrl,
            withCredentials: true
        });
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public getClient(): AxiosInstance {
        return this.client;
    }

    public async get(path: string, config?: AxiosRequestConfig) {
        return this.client.get(path, config);
    }

    public async post(path: string, data?: any, config?: AxiosRequestConfig) {
        return this.client.post(path, data, config);
    }

    public async put(path: string, data?: any, config?: AxiosRequestConfig) {
        return this.client.put(path, data, config);
    }

    public async delete(path: string, config?: AxiosRequestConfig) {
        return this.client.delete(path, config);
    }

    public async patch(path: string, data?: any, config?: AxiosRequestConfig) {
        return this.client.patch(path, data, config);
    }
}

interface ClientCredentials {
    username: string;
    password: string;
    project: string;
    port: string;
}

class AuthApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async isAuthenticated(): Promise<boolean> {
        try {
            await this.api.get('/api/v0/auth');
            return true;
        } catch (error) {
            return false;
        }
    }

    public async authenticate(code: string): Promise<string> {
        const response = await this.api.post(`/api/v0/auth?code=${code}`);
        return response.data.token;
    }

    public async refreshToken(): Promise<string> {
        const response = await this.api.put('/api/v0/auth');
        return response.data.token;
    }

    public async deleteAccount(): Promise<boolean> {
        try {
            await this.api.delete('/api/v0/auth');
            return true;
        }
        catch (error) {
            LogMessage('Failed to delete account', Severity.ERROR);
            return false;
        }
    }

    public async getUserData(): Promise<any> {
        const response = await this.api.get('/api/v0/auth/data');
        return response.data;
    }

    public async getClientAuth(state: string): Promise<any> {
        const response = await this.api.post(`/api/v0/auth/client?state=${state}`);
        return response.data;
    }

    public async getRedirectUrl(): Promise<string> {
        const response = await this.api.get('/api/v0/auth/client/redirect');
        return response.data;
    }

    public async getAuthUrl(state: string): Promise<string> {
        const response = await this.api.get(`/api/v0/auth/client?state=${state}`);
        return response.data;
    }

    public async completeClientAuth(state: string): Promise<ClientCredentials> {
        const response = await this.api.get(`/api/v0/auth/client?state=${state}`);
        return response.data;
    }
}

interface ProjectStatus {
    online: boolean;
    public: boolean;
}

class ProjectsApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getProjects(): Promise<{ [key: string]: ProjectStatus }> {
        const response = await this.api.get('/api/v0/projects');
        return response.data;
    }
}

class ProjectApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async createProject(name: string): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${name}`);
            return true;
        } catch (error) {
            LogMessage('Failed to create project', Severity.ERROR);
            return false;
        }
    }
}

interface ProjectData {
    name: string;
    port: number;
    'database-status': boolean;
    'server-status': boolean;
    public: boolean;
}

interface UserInvite {
    "discord-name": string;
    "read-resources": boolean;
    "write-resources": boolean;
    "read-mods": boolean;
    "write-mods": boolean;
    deploy: boolean;
    login: boolean;
    "admin-level": number;
    "invite-users": boolean;
}

class AdminApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getMetadata(project: string): Promise<ProjectData> {
        const response = await this.api.get(`/api/v0/p/${project}/admin`);
        return response.data;
    }

    public async getAvailable(project: string): Promise<ProjectStatus> {
        const response = await this.api.get(`/api/v0/p/${project}/admin/available`);
        return response.data;
    }

    public async inviteUser(project: string, invite: UserInvite): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/invite`, invite);
            return true;
        } catch (error) {
            LogMessage('Failed to invite user', Severity.ERROR);
            return false;
        }
    }

    public async loadProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/load`);
            return true;
        } catch (error) {
            LogMessage('Failed to load project', Severity.ERROR);
            return false;
        }
    }

    public async saveProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/save`);
            return true;
        } catch (error) {
            LogMessage('Failed to save project', Severity.ERROR);
            return false;
        }
    }

    public async startProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/start`);
            return true;
        } catch (error) {
            LogMessage('Failed to start project', Severity.ERROR);
            return false;
        }
    }

    public async stopProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/stop`);
            return true;
        } catch (error) {
            LogMessage('Failed to stop project', Severity.ERROR);
            return false;
        }
    }

    public async restartProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/restart`);
            return true;
        } catch (error) {
            LogMessage('Failed to restart project', Severity.ERROR);
            return false;
        }
    }

    public async resetProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/reset`);
            return true;
        } catch (error) {
            LogMessage('Failed to reset project', Severity.ERROR);
            return false;
        }
    }

    public async deployProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/deploy`);
            return true;
        } catch (error) {
            LogMessage('Failed to deploy project', Severity.ERROR);
            return false;
        }
    }

    public async applyProject(project: string): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/admin/apply`);
            return true;
        } catch (error) {
            LogMessage('Failed to apply project', Severity.ERROR);
            return false;
        }
    }

    public async getVariables(project: string): Promise<{ [key: string]: string }> {
        const response = await this.api.get(`/api/v0/p/${project}/admin/variables`);
        return response.data;
    }

    public async setVariables(project: string, variables: { [key: string]: string | null }): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${project}/admin/variables`, variables);
            return true;
        } catch (error) {
            LogMessage('Failed to set variables', Severity.ERROR);
            return false;
        }
    }

    public async getIsPublic(project: string): Promise<boolean> {
        const response = await this.api.get(`/api/v0/p/${project}/admin/public`);
        return response.data;
    }

    public async setPublic(project: string, isPublic: boolean): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${project}/admin/public`, { public: isPublic });
            return true;
        } catch (error) {
            LogMessage('Failed to set public', Severity.ERROR);
            return false;
        }
    }
}

class SQLApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async query(project: string, query: string): Promise<{ [key: string]: string | number | boolean | null }[]> {
        try {
            const response = await this.api.post(`/api/v0/p/${project}/sql`, { 
                'sql': query
            });
            return response.data.result;
        } catch (error) {
            LogMessage('Failed to execute query, error: ' + error, Severity.ERROR);
            return [];
        }
    }

    public async queryApplied(project: string, query: string): Promise<{ [key: string]: string | number | boolean | null }[]> {
        try {
            const response = await this.api.post(`/api/v0/p/${project}/a/sql`, { 
                'sql': query
            });
            return response.data.result;
        } catch (error) {
            LogMessage('Failed to execute query, error: ' + error, Severity.ERROR);
            return [];
        }
    }

    public async schema(project: string): Promise<{ [table: string]: { name: string, type: string }[] }> {
        const response = await this.api.get(`/api/v0/p/${project}/sql/schema`);
        return response.data.schema;
    }
}

interface ModData {
    uid: number;
    json: any;
}

interface ModCreationData {
    uid: number;
    name: string;
}

class ModApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getMod(project: string, mod: string): Promise<ModData> {
        const response = await this.api.get(`/api/v0/p/${project}/mod/${mod}`);
        return response.data;
    }

    public async createMod(project: string, json: any): Promise<ModCreationData> {
        const response = await this.api.post(`/api/v0/p/${project}/mod`, json);
        return response.data;
    }

    public async updateMod(project: string, mod: string, json: any): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/mod/${mod}`, json);
            return true;
        } catch (error) {
            LogMessage(`Failed to update mod ${mod}, error: ${error}`, Severity.ERROR);
            return false;
        }
    }

    public async deleteMod(project: string, mod: string): Promise<boolean> {
        try {
            await this.api.delete(`/api/v0/p/${project}/mod/${mod}`);
            return true;
        } catch (error) {
            LogMessage(`Failed to delete mod ${mod}, error: ${error}`, Severity.ERROR);
            return false;
        }
    }
}

export interface PackageData {
    name: string;
    version: string;
    description: string;
    author: string;
    mods: string[];
}

export interface PackageCreationQuery
{
    version: string;
    description: string;
    author: string;
}

class PackageApi
{
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getPackage(project: string, packageId: string): Promise<PackageData> {
        const response = await this.api.get(`/api/v0/p/${project}/package/${packageId}`);
        return response.data;
    }

    public async getPackages(project: string): Promise<string[]> {
        const response = await this.api.get(`/api/v0/p/${project}/packages`);
        return response.data;
    }

    public async createPackage(project: string, packageId: string, query: PackageCreationQuery): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${project}/package/${packageId}`, query);
            return true;
        } catch (error) {
            LogMessage('Failed to create package', Severity.ERROR);
            return false;
        }
    }

    public async updatePackage(project: string, packageId: string, query: PackageCreationQuery): Promise<boolean> {
        try {
            await this.api.put(`/api/v0/p/${project}/package/${packageId}`, query);
            return true;
        } catch (error) {
            LogMessage('Failed to update package', Severity.ERROR);
            return false;
        }
    }

    public async deletePackage(project: string, packageId: string): Promise<boolean> {
        try {
            await this.api.delete(`/api/v0/p/${project}/package/${packageId}`);
            return true;
        } catch (error) {
            LogMessage('Failed to delete package', Severity.ERROR);
            return false;
        }
    }

    public async savePackage(project: string, packageId: string): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${project}/package/${packageId}/save`);
            return true;
        } catch (error) {
            LogMessage('Failed to save package', Severity.ERROR);
            return false;
        }
    }

    public async renamePackage(project: string, packageId: string, newPackageId: string): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${project}/package/${packageId}/rename`, {
                'name': newPackageId
            });
            return true;
        } catch (error) {
            LogMessage('Failed to rename package', Severity.ERROR);
            return false;
        }
    }
}

class ImportApi
{
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async importObject(project: string, resource: string, file: File): Promise<boolean> {
        try {
            const formData = new FormData();
            formData.append('file', file);
            await this.api.getClient().post(`/api/v0/p/${project}/import/model/${resource}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return true;
        } catch (error) {
            LogMessage('Failed to import object', Severity.ERROR);
            return false;
        }
    }
}

class ResourceApi
{
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public resourceUrl(project: string, resource: string): string {
        return `${this.api.getBaseUrl()}/api/v0/p/${project}/resource/${resource}`;
    }

    public async getResource(project: string, resource: string): Promise<string> {
        const response = await this.api.get(`/api/v0/p/${project}/resource/${resource}`);
        return response.data;
    }

    public async uploadResource(project: string, resource: string, file: File): Promise<boolean> {
        try {
            const formData = new FormData();
            formData.append('file', file);
            await this.api.getClient().post(this.resourceUrl(project, resource), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return true;
        } catch (error) {
            LogMessage('Failed to upload resource', Severity.ERROR);
            return false;
        }
    }

    public async deleteResource(project: string, resource: string): Promise<boolean> {
        try {
            await this.api.delete(`/api/v0/p/${project}/resource/${resource}`);
            return true;
        } catch (error) {
            LogMessage('Failed to delete resource', Severity.ERROR);
            return false;
        }
    }

    public imageUrl(project: string, resource: string): string {
        return `${this.api.getBaseUrl()}/api/v0/p/${project}/resource/f/image/${resource}`;
    }

    public iconUrl(project: string, resource: string): string {
        return `${this.api.getBaseUrl()}/api/v0/p/${project}/resource/f/icon/${resource}`;
    }

    public async getGeometryUrl(project: string, resource: string): Promise<string | null> {
        const response = await this.api.get(`/api/v0/p/${project}/resource/f/geometry/${resource}`);
        if (response.status !== 200) {
            return null;
        }
        
        if (false) {
            // Remove first 'cache' part of the URL
            const url = response.data.url;
            const parts = url.split('/');
            var finalUrl = parts.slice(2).join('/');

            return 'http://localhost:8080/api/' + finalUrl;
        }

        return response.data.url;
    }

    public async getWorld(project: string, resource: string): Promise<World> {
        const response = await this.api.get(`/api/v0/p/${project}/resource/f/world/${resource}`);
        return response.data;
    }

    public async getTerrain(project: string, resource: string): Promise<Terrain> {
        const response = await this.api.get(`/api/v0/p/${project}/resource/f/terrain/${resource}`);
        return response.data;
    }

    public async getDirectory(project: string, resource: string, directories: boolean): Promise<string[]> {
        const response = await this.api.get(`/api/v0/p/${project}/resource/d/${resource}?directories=${directories?'true':'false'}`);
        return response.data.resources;
    }

    public async createDirectory(project: string, resource: string): Promise<boolean> {
        try {
            await this.api.post(`/api/v0/p/${project}/resource/d/${resource}`);
            return true;
        } catch (error) {
            LogMessage('Failed to create directory', Severity.ERROR);
            return false;
        }
    }

    public async deleteDirectory(project: string, resource: string): Promise<boolean> {
        try {
            await this.api.delete(`/api/v0/p/${project}/resource/d/${resource}`);
            return true;
        } catch (error) {
            LogMessage('Failed to delete directory', Severity.ERROR);
            return false;
        }
    }
}

class LookupApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getEntireLookup(project: string): Promise<{ [key: string]: number }> {
        const response = await this.api.get(`/api/v0/p/${project}/lookup`);
        return response.data;
    }

    public async getLookup(project: string, symbols: string[]): Promise<{ [key: string]: number }> {
        const response = await this.api.get(`/api/v0/p/${project}/lookup?symbols=${symbols.join(',')}`);
        return response.data;
    }
}

interface LocaleData {
    localized: boolean;
    value: { [key: string]: string };
}

class LocaleApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getLocale(project: string, keys: string[]): Promise<{ [key: string]: LocaleData }> {
        const response = await this.api.get(`/api/v0/p/${project}/locale?keys=${keys.join(',')}`);
        return response.data;
    }    
}

class TypeApi {
    private api: NejlikaApi;

    constructor(api: NejlikaApi) {
        this.api = api;
    }

    public async getType(project: string, type: string): Promise<Template|null> {
        try {
            const response = await this.api.get(`/api/v0/p/${project}/type/${type}`);
            return response.data;
        } catch (error) {
            LogMessage('Failed to get type', Severity.ERROR);
            return null;
        }
    }

    public async validateAgainstType(project: string, type: string, data: any): Promise<string> {
        try {
            await this.api.post(`/api/v0/p/${project}/type/${type}/validate`, data);
            return '';
        } catch (error) {
            LogMessage('Failed to validate against type', Severity.ERROR);
            if (axios.isAxiosError(error)) {
                return error.response?.data?.error || 'Unknown error';
            }
            return 'Unknown error';
        }
    }

    public async getTypes(project: string): Promise<string[]> {
        const response = await this.api.get(`/api/v0/p/${project}/types`);
        return response.data;
    }

    public async validate(project: string, data: any): Promise<string> {
        try {
            await this.api.post(`/api/v0/p/${project}/types/validate`, data);
            return '';
        } catch (error) {
            LogMessage('Failed to validate against type', Severity.ERROR);
            if (axios.isAxiosError(error)) {
                return error.response?.data?.error || 'Unknown error';
            }
            return 'Unknown error';
        }
    }
}

// Create instance of NejlikaApi
const api = new NejlikaApi('/');

// Create instances of the different APIs
const authApi = new AuthApi(api);
const projectsApi = new ProjectsApi(api);
const projectApi = new ProjectApi(api);
const adminApi = new AdminApi(api);
const sqlApi = new SQLApi(api);
const modApi = new ModApi(api);
const packageApi = new PackageApi(api);
const importApi = new ImportApi(api);
const resourceApi = new ResourceApi(api);
const lookupApi = new LookupApi(api);
const localeApi = new LocaleApi(api);
const typeApi = new TypeApi(api);

export {
    api,
    authApi,
    projectsApi,
    projectApi,
    adminApi,
    sqlApi,
    modApi,
    packageApi,
    importApi,
    resourceApi,
    lookupApi,
    localeApi,
    typeApi
};
