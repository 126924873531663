import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { authorization, Permission } from '../../Authorization';

import './Resources.css';
import { ModelViewer } from '../Graphics/ModelViewer';
import { GenericFile } from '../GenericFile';
import { GenericInput } from '../GenericInput';
import { GenericBtn } from '../GenericBtn';
import { importApi, packageApi, projectApi, resourceApi } from '../NejlikaApi';
import { ResourceExplorerDirectory } from './ResourceExplorerDirectory';
import { ResourceExplorerFile } from './ResourceExplorerFile';
import FileDropZone from './FileDropZone';
import { on } from 'events';

interface ResourcesProps {
    project: string;
};

let onRequestedFileSelection: ((callback: (value: string) => void, filter: string[]) => void) | null = null;

let globalOnSelectResourceCallback: ((value: string) => void) | null = null;

export const RequestFileSelection = (callback: (value: string) => void, filter: string[]) => {
    if (onRequestedFileSelection) {
        onRequestedFileSelection(callback, filter);
    }

    globalOnSelectResourceCallback = callback;
}

export const Resources: React.FC<ResourcesProps> = ({project}) => {
    const [files, setFiles] = useState<string[]>([]);
    const [direcotries, setDirecotries] = useState<string[]>([]);
    const [selectedPath, setSelectedPath] = useState<string>("");
    const [selectedPack, setSelectedPack] = useState<string>("lego-universe");
    const [newDirectoryName, setNewDirectoryName] = useState<string>("");
    const [packages, setPackages] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [lastSelected, setLastSelected] = useState<string | null>(null);
    const [lastSelectedPack, setLastSelectedPack] = useState<string>("");
    const [lastSelectedPath, setLastSelectedPath] = useState<string>("");
    const [modelUrl, setModelUrl] = useState<string | null>(null);
    const [fileFilter, setFileFilter] = useState<string[]>([]);
    const [onSelectResourceCallback, setOnSelectResourceCallback] = useState<((value: string) => void) | null>(null);

    useEffect(() => {
        packageApi.getPackages(project).then(data => {
            data.push("lego-universe");

            setPackages(data);
        });

        onRequestedFileSelection = (callback, filter) => {
            setLastSelected(null);

            setFileFilter(filter);
            setOnSelectResourceCallback(callback);

            console.log(`Requesting file selection with filter: ${filter}`, callback);
        };
    }, [project]);

    const selectRequestedFile = useCallback((value: string) => {
        console.log("Selected file: " + value, onSelectResourceCallback);

        if (onSelectResourceCallback) {
            onSelectResourceCallback(value);
        }

        if (globalOnSelectResourceCallback) {
            globalOnSelectResourceCallback(value);
        }

        setOnSelectResourceCallback(null);
        setFileFilter([]);

        globalOnSelectResourceCallback = null;
    }, [onSelectResourceCallback]);

    const updateFiles = useCallback(() => {
        resourceApi.getDirectory(project, `${selectedPack}:${selectedPath}`, true).then(data => {
            let f: string[] = [], d: string[] = [];

            data.forEach(file => {
                if (file.includes(".")) {
                    f.push(file);
                } else {
                    d.push(file);
                }
            });

            setFiles(f);
            setDirecotries(d);
        });
    }, [selectedPack, selectedPath, project]);

    useEffect(() => {
        updateFiles();
    }, [updateFiles]);

    const onSelect = useCallback((value: string, pack: string) => {
        const id = `${pack}:${value}`;

        setLastSelected(id);
    }, []);

    const onCreateDirectory = useCallback(() => {
        if (newDirectoryName === "") {
            setError("Directory name cannot be empty.");
            return;
        }

        // Check if the directory already exists
        if (direcotries.includes(newDirectoryName)) {
            setError("Directory already exists.");
            return;
        }

        // Make sure the directory does not contain any invalid characters, only alphanumeric and underscores
        if (!newDirectoryName.match(/^[a-zA-Z0-9_]+$/)) {
            setError("Directory name can only contain alphanumeric characters and underscores.");
            return;
        }

        let path = selectedPath;
        
        if (!path.endsWith("/")) {
            path += "/";
        }

        path += newDirectoryName;
        
        resourceApi.createDirectory(project, `${selectedPack}:${selectedPath}`).then((success) => {
            if (!success) {
                setError("Failed to create directory.");
                return;
            }

            setNewDirectoryName("");
            setError(null);
            
            updateFiles();
        });
    }, [selectedPack, selectedPath, newDirectoryName, project]);

    const onDelete = useCallback((file: string) => {
        resourceApi.deleteResource(project, `${selectedPack}:${selectedPath}${file}`).then((success) => {
            if (!success) {
                setError("Failed to delete file.");
                return;
            }

            setError(null);
            
            updateFiles();
            
            setLastSelected(null);
        });
    }, [selectedPack, selectedPath, project]);

    const handleFileUpload = useCallback((file: File) => {
        let path = selectedPath;
        
        if (!path.endsWith("/")) {
            path += "/";
        }

        path += file.name;

        if (file.name.endsWith(".obj")) {
            importApi.importObject(project, `${selectedPack}:${path}`, file).then((success) => {
                if (!success) {
                    setError("Failed to import object.");
                    return;
                }

                setError(null);
                
                updateFiles();
            });

            return;
        }

        resourceApi.uploadResource(project, `${selectedPack}:${path}`, file).then((success) => {
            if (!success) {
                setError("Failed to upload file.");
                return;
            }

            setError(null);
            
            updateFiles();
        });
    }, [selectedPack, selectedPath, project]);

    useEffect(() => {
        if (lastSelected) {
            const parts = lastSelected.split(":");
            setLastSelectedPack(parts[0]);
            setLastSelectedPath(parts[1]);

            if (parts[1].endsWith(".nif") || parts[1].endsWith(".kfm")) {
                resourceApi.getGeometryUrl(project, lastSelected).then(setModelUrl);
            }
            else {
                setModelUrl(null);
            }
        }
        else {
            setLastSelectedPack("");
            setLastSelectedPath("");
            setModelUrl(null);
        }
    }, [lastSelected, project]);

    const onFileDrop = useCallback((files: File[]) => {
        files.forEach(file => {
            handleFileUpload(file);
        });
    }, [handleFileUpload]);

    const parts = selectedPath.split("/");

    const canWriteResouces = authorization.hasPermission(Permission.WriteResources) && selectedPack !== "lego-universe" && selectedPack !== "wonderland";

    const canEditLastSelected = lastSelected && canWriteResouces && lastSelectedPack !== "lego-universe" && lastSelectedPack !== "wonderland";

    const isRequestingFile = onSelectResourceCallback !== null;

    return (
        <div className={`logger vs-bg dragable-size ${isRequestingFile ? 'slow-flash-border' : ''}`}>
            <div className='d-flex flex-column h-100'>
                <div className="p-1 left-text">
                    <p className="wheat-text monospace w-100">
                        <select
                            className="wheat-text vs-bg monospace m-1 b-0"
                            value={selectedPack}
                            onChange={(e) => {
                                setSelectedPack(e.target.value);
                                setSelectedPath("");
                            }}
                        >
                            {packages.map((pack, index) => (
                                <option
                                    className="wheat-text vs-bg monospace"
                                    key={index}
                                    value={pack}
                                >
                                    {pack}
                                </option>
                            ))}
                        </select>
                        :
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                className="pointer"
                                onClick={() => {
                                    const parts = selectedPath.split("/");
                                    parts.splice(index + 1, parts.length - index - 1);
                                    setSelectedPath(parts.join("/"));
                                }}
                            >
                                <u>{part}</u>
                                {index < parts.length - 1 && <span>/</span>}
                            </span>
                        ))}
                    </p>
                </div>
                <div className="row flex-grow-1 m-0 overflow-hidden">
                    {/* Left Column */}
                    <div className="left-text col-3 p-3 content-column border-right">
                        <ResourceExplorerDirectory
                            
                            path={".."}
                            onSelect={() => {
                                const parts = selectedPath.split("/");
                                parts.pop();
                                setSelectedPath(parts.join("/"));
                            }}
                        />
                        {direcotries.map((directory, index) => (
                            <ResourceExplorerDirectory
                                key={index}
                                path={directory}
                                onSelect={() => {
                                    setSelectedPath(directory);
                                }}
                            />
                        ))}
                    </div>
                    {/* Center Column */}
                    <FileDropZone
                        files={files}
                        project={project}
                        selectedPack={selectedPack}
                        selectedPath={selectedPath}
                        lastSelected={lastSelected}
                        fileFilter={fileFilter}
                        onSelect={onSelect}
                        onDelete={onDelete}
                        onDropFiles={onFileDrop}
                    />
                    {/*<div className="col-6 p-3 content-column">
                        <Row>
                            {files.map((file, index) => (
                                <ResourceExplorerFile
                                    project={project}
                                    key={index}
                                    pack={selectedPack}
                                    path={file}
                                    highlight={lastSelected === `${selectedPack}:${file}` || fileFilter.includes(file.split(".")?.pop() ?? "") }
                                    onSelect={() => {
                                        onSelect(file, selectedPack);
                                    }}
                                    onDeleted={() => {
                                        onDelete(file);
                                    }}
                                />
                            ))}
                        </Row>
                    </div>*/}
                    {/* Right Column */}
                    <div className="col-3 p-3 content-column border-left">
                        {lastSelected && (
                            <>
                            <div className="p-1 center-text">
                                <p className="wheat-text monospace w-100">
                                    {lastSelected}
                                </p>
                            </div>
                            <div className="p-1 center-text">
                                {(lastSelected.endsWith(".dds")) && (
                                    <img
                                        src={resourceApi.imageUrl(project, `${lastSelectedPack}:${lastSelectedPath}`)}
                                        alt={lastSelected}
                                        width={100}
                                        height={100}
                                        className="resource-explorer-image"
                                    />
                                )}
                                {modelUrl && (
                                    <>
                                    <ModelViewer
                                        modelUrl={modelUrl}
                                    />
                                    <GenericBtn title="Open in Model Viewer" onClick={() => {
                                        const url = `${window.location.origin}/embed/${selectedPath.endsWith(".nif") ? "model" : "animation"}/${lastSelected}`;

                                        window.open(url, "_blank");
                                    }} />
                                    </>
                                )}
                            </div>
                            <div className="p-1 center-text">
                                <Row>
                                    <Col md={4}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                navigator.clipboard.writeText(lastSelected);

                                                if (isRequestingFile) {
                                                    selectRequestedFile(lastSelected);
                                                }
                                            }}
                                        >
                                            Select
                                        </button>
                                    </Col>
                                    <Col md={4}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setLastSelected(null);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </Col>
                                    <Col md={4}>
                                        {canEditLastSelected && (
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    onDelete(lastSelectedPath);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            </>
                        )}
                        {(!lastSelected) && canWriteResouces && (
                            <>
                            <Row className='p-0 m-0'>
                                <Col md={12}>
                                    <GenericFile title="" types={[]} onUpload={handleFileUpload} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row className='pt-2'>
                                        <Col md={8}>
                                            <GenericInput title="" type="text" value={newDirectoryName} setValue={setNewDirectoryName} />
                                        </Col>
                                        <Col md={4}>
                                            <GenericBtn title="Create Directory" onClick={onCreateDirectory} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}