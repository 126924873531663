import { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import logo from './logo.svg';
import './App.css';
import { Navigation } from './components/Navigation';
import { Editor } from './components/Editor';
import { Explorer } from './components/Explorer';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from './Home';
import ExplorerHome from './ExplorerHome';
import LoadHome from './LoadHome';
import { Auth } from './components/Auth';
import Cookies from 'js-cookie';
import { GenericBtn } from './components/GenericBtn';
import NewModHome from './NewModHome';
import { ThreeDots } from 'react-loading-icons';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import CookieBanner from './components/CookieBanner/CookieBanner';
import ImportantNoticeModal from './components/ImportantNoticeModal/ImportantNoticeModal';
import { Button } from 'react-bootstrap';
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';
import { LauncherPage } from './components/Launcher/LauncherPage';
import { EditorMode } from './components/EditorMode';
import { ModelViewer } from './components/Graphics/ModelViewer';
import { ModelEmbedded } from './components/Graphics/ModelEmbedded';
import { WorldEmbedded } from './components/Graphics/WorldEmbedded';
import { AnimationEmbedded } from './components/Graphics/AnimationEmbedded';
import { InitialUserSettingsState, InterfaceUserSettingsProps, interfaceUserSettingsReducer } from './components/InterfaceUserSettings';
import { TerrainEmbedded } from './components/Graphics/TerrainEmbedded';
import { authApi } from './components/NejlikaApi';

function App() {
    const [state, dispatch] = useReducer(interfaceUserSettingsReducer, InitialUserSettingsState, (init) => {
        // Initialize state from localStorage
        const stored = localStorage.getItem("interfaceSettings");
        return stored ? JSON.parse(stored) : init;
    });

    useEffect(() => {
        // Persist state to localStorage on changes
        localStorage.setItem("interfaceSettings", JSON.stringify(state));
    }, [state]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Standard />}>
                        <Route index element={<WelcomePage />} />
                        <Route path='launcher' element={<LauncherPage />} />
                    </Route>
                    <Route path="/editor" element={<EdiorPage
                        userSettings={state}
                        dispatchUserSettings={dispatch}
                    />}>
                        <Route index element={<Home
                            userSettings={state}
                            dispatchUserSettings={dispatch}
                        />} />
                        <Route path='load' element={<LoadHome />} />
                        <Route path='new' element={<NewModHome />} />
                    </Route>
                    <Route path='auth' element={<Auth />} />
                    <Route path='/privacy' element={<PrivacyPolicy />} />
                    <Route path="/embed">
                        <Route path='model/*' element={<ModelEmbedded />} />
                        <Route path='animation/*' element={<AnimationEmbedded />} />
                        <Route path='zone/:zone' element={<WorldEmbedded />} />
                        <Route path='terrain/*' element={<TerrainEmbedded />} />
                    </Route>
                    <Route path='*' element={<p>404 :/</p>} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

const Standard: React.FC = () => {
    return (
        <>
            <ImportantNoticeModal />
            <div className="App vs-bg">
                <Outlet />
            </div>
            <CookieBanner />
        </>
    );
};

type EdiorProps = InterfaceUserSettingsProps;

const EdiorPage: React.FC<EdiorProps> = ({
    userSettings,
    dispatchUserSettings
}) => {
    const [hasAuth, setHasAuth] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const [myInterval, setMyInterval] = useState<any>(null);
    const [_lastAuth, _setLastAuth] = useState<number>(Cookies.get('lastAuth') ? parseInt(Cookies.get('lastAuth')!) : 0);

    const lastAuth = useRef<number>(_lastAuth);

    const setLastAuth = useCallback((value: number) => {
        _setLastAuth(value);
        lastAuth.current = value;
    }, [lastAuth]);

    const attemptFirstAuth = useCallback(() => {
        authApi.isAuthenticated().then(success => {
            setHasAuth(true);

            if (!success) {
                setFailed(true);

                return;
            }

            setFailed(false);
        });
    }, []);

    const attemptToAuth = useCallback(() => {
        if (lastAuth.current + 10000 > Date.now()) {
            return;
        }

        setLastAuth(Date.now());

        Cookies.set('lastAuth', Date.now().toString(), { expires: 3600, path: '/', sameSite: 'none', secure: true });

        authApi.isAuthenticated().then(success => {
            setHasAuth(true);

            if (!success) {
                setFailed(true);

                return;
            }

            setFailed(false);
        });
    }, [lastAuth]);

    useEffect(() => {
        attemptFirstAuth();

        clearInterval(myInterval);

        const interval = setInterval(() => {
            attemptToAuth();
        }, 5000);

        setMyInterval(interval);
    }, [attemptFirstAuth, attemptToAuth]);

    const login = useCallback(() => {
        window.location.href = 'https://discord.com/oauth2/authorize?client_id=1214686736358051930&response_type=code&redirect_uri=https%3A%2F%2Fnejlika.org%2Fauth&scope=identify';
    }, []);

    // Add vs-bg to the body
    useEffect(() => {
        document.body.classList.add('vs-bg');

        return () => {
            document.body.classList.remove('vs-bg');
        }
    }, []);

    return (
        <>
            <ImportantNoticeModal />
            {hasAuth && <>
                {!failed &&
                    <div className="App vs-bg">
                        <header className='main-header vs-bg fixed-top'>
                            <Navigation
                                userSettings={userSettings}
                                dispatchUserSettings={dispatchUserSettings}
                            />
                        </header>
                        <div className='main-header vs-bg'>
                        </div>
                        <Outlet />
                    </div>
                }
                {failed &&
                    <div className="App vs-bg">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <div className="text-center">
                                <Button onClick={login} variant="primary">Login</Button>
                            </div>
                        </div>
                    </div>
                }
            </>}
            {!hasAuth &&
                <div className="App vs-bg">
                    <div className="position-absolute top-50 start-50 translate-middle">

                    </div>
                </div>
            }
            <CookieBanner />
        </>
    );
}

export default App;
